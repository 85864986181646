import React from "react";
import { Link } from "react-router-dom";
import { useThemeChange } from "../../utils/themeChange";

const Footer = () => {
  const themeChange = useThemeChange();
  return (
    <div className={themeChange.add("section-2")}>
      <footer
        className="footer-light"
        style={{
          padding: "0px",
          bottom: 0,
          width: "100%",
        }}
      >
        <div className="subfooter" style={{ marginTop: "0px" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="de-flex">
                  <div className="de-flex-col">
                    <span onClick={() => window.open("", "_self")}>
                      <span className="copy">
                        &copy; Copyright 2023 - ArtSwap LLC
                      </span>
                    </span>
                  </div>
                  <div className="de-flex-col">
                    <Link to="/privacy-policy">Privacy Policy</Link>

                    <Link
                      to="/terms-and-conditions"
                      style={{
                        marginLeft: "30px",
                      }}
                    >
                      Terms & Conditions
                    </Link>

                    <a
                      href="https://medium.com/@artswap"
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        marginLeft: "30px",
                      }}
                    >
                      Blog
                    </a>

                    {/* <Link
                      to="/login"
                      style={{
                        marginLeft: "30px",
                      }}
                    >
                      Login
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
export default Footer;
