import React from "react";
import Spinner from "react-bootstrap/Spinner";
import { createGlobalStyle } from "styled-components";

const GLobalStyle = createGlobalStyle`
.mr-1{
  margin-right:1rem
}
`;
const Loader  =(props)=>{
    const {message} = props


return(
    <>
    <GLobalStyle/>
    <Spinner animation="border" />
    {message?.length?message:" "}
    </>
);
};
export default Loader
