import React, { useCallback, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import useAuthStore from "../store/authStore";

export default function useRouteProtect(routeConfig) {
  const { user } = useAuthStore((state) => ({ user: state.userLog }));
  const [routes, setRoutes] = useState(null);

  const filterRoutes = useCallback(() => {
    const filtered = routeConfig.map((route) => {
      if (!route?.protected && !route?.checkBlacklist) return route;

      if (route?.protected && !user?.access_token)
        return {
          ...route,
          element: <Navigate to="/login" replace={true} />,
        };

      return route;
    });

    setRoutes(filtered);
  }, [routeConfig, user?.access_token]);

  useEffect(() => filterRoutes(), [filterRoutes]);

  return routes;
}
