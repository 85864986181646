import React from "react";
import Header from "../../components/menu";

import { createGlobalStyle } from "styled-components";
import Footer from "../components/footer";

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
  header#myHeader.navbar.sticky.white {
    background: #212428;
    border-bottom: 0;
    box-shadow: 0 4px 20px 0 rgba(10,10,10, .8);
  }
  .btn2{
    background: #212428;
    color: #fc202c !important;
    border: 1px solid rgba(255, 52, 63);
  }
  .mr2{
    margin-right:2rem
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: #fff;
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: none !important;
  }
  header#myHeader .logo .d-3{
    display: block !important;
  }
 
  footer.footer-light .subfooter span img.d-1{
    display: none !important;
  }
  footer.footer-light .subfooter span img.d-3{
    display: inline-block !important;
  }
  .de_countdown{
    right: 10px;
    color: #fff;
  }
  .author_list_pp{
    margin-left:0;
  }
  footer .footer-light .subfooter{
    border-top: 1px solid rgba(255,255,255,.1);
  }
`;

function Layout({ children }) {
  return (
    <>
      <GlobalStyles />
      <Header />
      <div style={{ position: "relative", minHeight: "100vh" }}>{children}</div>
      <Footer />
    </>
  );
}

export default Layout;
