import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }
  componentDidCatch(error, info) {
    console.error("ErrorBoundary caught an error", error, info);
  }
  render() {
    if (this.state.hasError) {
      return (
        <>
        <header id="myHeader" className="navbar white">
          <div className="container">
            <div className="row w-100-nav">
              <div className="logo px-0">
                <div
                  className="navbar-title navbar-item"
                  style={{ width: "145px", cursor: "pointer" }}
                >
                  <a href="/">
                    <img
                      src={"/img/art-lowres.png"}
                      className="img-fluid d-block"
                      alt="#"
                      width={145}
                    />
                  </a>
                </div>
              </div>
            </div>
            </div>
        </header>
            <section className="container">
              <div>
                 {/* className="row mt-md-5 pt-md-4" */}
               
                <div className="error">
                {/* // className="col-md-6 col-sm-12 text-center"> */}
                  <p>
                    Something went wrong, Please contact us or try again later.
                  </p>
                </div>{" "}
              </div>
            </section>
            </>      
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
