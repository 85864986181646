import React from "react";

const Button = ({
  primary = true,
  style = {},
  rounded = true,
  children,
  ...props
}) => {
  const customRoundedStyle = rounded ? { borderRadius: 30 } : {};

  return (
    <>
      <button
        style={{ ...customRoundedStyle, ...style }}
        className={primary ? "btn-main" : "btn-secondary"}
        {...props}>
        {children}
      </button>
    </>
  );
};

export default Button;
