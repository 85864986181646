import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTopBtn from "./components/menu/ScrollToTop";
import RouteHandler from "./router";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ToastContextProvider } from "./modules/toast/context";
import ErrorBoundary from "./components/error-boundary/ErrorBoundary";
import { ThemeContextProvider } from "./modules/themes";
import Loading from "./components/components/PageLoading/Loading";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location]);
  return children;
};

const queryClient = new QueryClient();

const App = () => {
  return (
    <ThemeContextProvider>
      <Suspense fallback={<Loading />}>
        <ErrorBoundary>
          <Router>
            <ToastContextProvider>
              <ScrollTop path="/">
                <QueryClientProvider client={queryClient}>
                  <Routes>
                    <Route path="*" element={<RouteHandler />}></Route>
                  </Routes>
                </QueryClientProvider>
              </ScrollTop>
            </ToastContextProvider>
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              theme="dark"
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </Router>
        </ErrorBoundary>
        <ScrollToTopBtn />
      </Suspense>
    </ThemeContextProvider>
  );
};
export default App;
