import React from "react";
import Layout from ".";

export function withLayout(Component) {
  return function contextComponent(props) {
    return (
      <Layout>
        <Component {...props} />
      </Layout>
    );
  };
}
