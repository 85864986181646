import React from "react";
import ReactDOM from "react-dom";
import "./assets/animated.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "../node_modules/elegant-icons/style.css";
import "../node_modules/et-line/style.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.js";
import "./assets/style.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// import { SettingsContextProvider } from './modules/';



ReactDOM.render(
  
    // <SettingsContextProvider>
      <App />,
    // </SettingsContextProvider>,

  document.getElementById("root")
);
serviceWorker.unregister();
