import React, { lazy, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ROUTES from "../views";
import useRouteProtect from "../hooks/useRouteProtect";
import useAuthStore from "../store/authStore";
import { useToastContext } from "../modules/toast/context";
import { checkTokenValidity } from "../utils/token";

const UNKNOWN = lazy(() => import("../views/unknown-views"));

const RouteHandler = () => {
  const { showError } = useToastContext();
  const location = useLocation();
  const { access_token } = useAuthStore((state) => ({
    access_token: state.userLog?.access_token,
  }));
  const routes = useRouteProtect(ROUTES);

  useEffect(() => {
    if (access_token) {
      const isValid = checkTokenValidity(access_token);
      if (!isValid) {
        showError("Your session has expired");
        sessionStorage.clear();
        window.location.href = "/login";
      }
    }
  }, [access_token, location.pathname]);
  return (
    <Routes>
      {routes?.length > 0 &&
        routes.map((routeConfig, index) => {
          return <Route {...routeConfig} key={index} />;
        })}
      <Route path="*" element={<UNKNOWN />} />
    </Routes>
  );
};

export default RouteHandler;
