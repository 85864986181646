import React, { useEffect, useState } from "react";
import Breakpoint, {
  BreakpointProvider,
  setDefaultBreakpoints,
} from "react-socks";
import { Link, useNavigate } from "react-router-dom";
import useOnclickOutside from "react-cool-onclickoutside";
import { useThemeContext } from "../../modules/themes";
import { useThemeChange } from "../../utils/themeChange";

import useAuthStore from "../../store/authStore";
import Button from "../components/Button/Button";

setDefaultBreakpoints([{ xs: 0 }, { l: 1199 }, { xl: 1200 }]);

const NavLink = (props) => (
  <Link
    {...props}
    // getProps={({ isCurrent }) => {
    //   // the object returned here is passed to the
    //   // anchor element's props
    //   return {
    //     className: isCurrent ? "active" : "non-active",
    //   };
    // }}
  />
);

const Header = function () {
  const { theme, handleThemeChange } = useThemeContext();
  const { user } = useAuthStore((state) => ({
    user: state.userLog,
  }));
  const navigate = useNavigate();
  const [showpop, btn_icon_pop] = useState(false);
  const [showmenu, btn_icon] = useState(false);
  const themeChange = useThemeChange();

  const closePop = () => {
    btn_icon_pop(false);
  };

  const refpop = useOnclickOutside(() => {
    closePop();
  });

  const logout = () => {
    sessionStorage.clear();
    window.location.reload();
    window.location.href = "/login";
  };

  useEffect(() => {
    const header = document.getElementById("myHeader");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      btn_icon(false);
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        totop.classList.add("show");
      } else {
        header.classList.remove("sticky");
        totop.classList.remove("show");
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  return (
    <div className={themeChange.add("section-2")}>
      <header id="myHeader" className="navbar white">
        <div className="container">
          <div className="row w-100-nav">
            <div className="logo px-0">
              <div
                className="navbar-title navbar-item"
                style={{ width: "145px" }}
              >
                <NavLink to="/">
                  <img
                    src={
                      theme === "dark"
                        ? "/img/art-lowres-dark.png"
                        : "/img/art-lowres-light.png"
                    }
                    className="img-fluid d-3"
                    alt="#"
                    width={145}
                  />
                </NavLink>
              </div>
            </div>
            <div className="search"></div>
            <BreakpointProvider>
              <Breakpoint l down>
                {showmenu && (
                  <div className="menu">
                    <div className="navbar-item">
                      <NavLink to="/" onClick={() => btn_icon(!showmenu)}>
                        Home
                      </NavLink>
                    </div>
                    <div className="navbar-item">
                      <NavLink
                        to="/why-artswap"
                        onClick={() => btn_icon(!showmenu)}
                      >
                        Why Artswap
                      </NavLink>
                    </div>
                    <div className="navbar-item">
                      <NavLink to="/about" onClick={() => btn_icon(!showmenu)}>
                        About
                      </NavLink>
                    </div>
                    <div className="navbar-item">
                      <div className="toggle">
                        <span
                          style={
                            theme === "dark"
                              ? { color: "#fff" }
                              : { color: "#04111C" }
                          }
                        >
                          Light Mode
                        </span>
                        <label>
                          <input
                            type="checkbox"
                            defaultChecked={theme === "dark" ? false : true}
                            onClick={handleThemeChange}
                          />
                          <span />
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </Breakpoint>

              <Breakpoint xl>
                <div className="menu">
                  <div className="navbar-item">
                    <NavLink to="/">Home</NavLink>
                  </div>
                  <div className="navbar-item">
                    <NavLink to="/why-artswap">Why Artswap</NavLink>
                  </div>
                  <div className="navbar-item">
                    <NavLink to="/about">About</NavLink>
                  </div>
                </div>
              </Breakpoint>
            </BreakpointProvider>

            <div className="mainside d-flex justify-content-between align-items-center gap-3">
              <div
                className="light-dark-btn d-none d-xl-block"
                onClick={handleThemeChange}
                style={{ marginRight: "5px" }}
              >
                <i
                  className={theme === "dark" ? "fa fa-moon-o" : "fa fa-sun-o"}
                  style={{ color: "white" }}
                ></i>
              </div>
              {!user && (
                <Button children="Login" onClick={() => navigate("/login")} />
              )}
              {user && (
                <div
                  id="de-click-menu-profile"
                  className="de-menu-profile"
                  onClick={() => btn_icon_pop(!showpop)}
                  ref={refpop}
                >
                  {user?.profileImage ? (
                    <img src={user?.profileImage} alt="" />
                  ) : (
                    <div className="dummy-avatar-text">
                      <p>{user?.firstName.charAt(0)}</p>
                    </div>
                  )}

                  {showpop && (
                    <div className="popshow">
                      <div className="d-name">
                        <h4>
                          {user?.firstName
                            .toUpperCase()
                            .concat(" ", user?.lastName.toUpperCase())}
                        </h4>
                      </div>
                      <ul className="de-submenu-profile">
                        <li>
                          <NavLink
                            to="/profile"
                            style={{
                              background: "none",
                              boxShadow: "none",
                              padding: "0px",
                              margin: "0px",
                            }}
                          >
                            <span>
                              <i className="fa fa-user"></i> Profile
                            </span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/edit-profile"
                            style={{
                              background: "none",
                              boxShadow: "none",
                              padding: "0px",
                              margin: "0px",
                            }}
                          >
                            <span>
                              <i
                                className="fa fa-pencil fa-sm"
                                style={{ paddingLeft: "7px" }}
                              ></i>{" "}
                              Edit Profile
                            </span>
                          </NavLink>
                        </li>
                        <li>
                          <span onClick={logout} className="signout-menu-item">
                            <i className="fa fa-sign-out"></i> Sign Out
                          </span>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
            <div className="menu-line white"></div>
            <div className="menu-line1 white"></div>
            <div className="menu-line2 white"></div>
          </button>
        </div>
      </header>
    </div>
  );
};
export default Header;
