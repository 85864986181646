import React, { lazy } from "react";
import { withLayout } from "../../components/layout/withLayoutHOC";

const Login = withLayout(
  lazy(() => import("./index").then((module) => ({ default: module.Login })))
);

const SignUp = withLayout(
  lazy(() => import("./index").then((module) => ({ default: module.SignUp })))
);

const AUTH_ROUTES = [
  {
    path: "/login",
    element: <Login />,
    protected: false,
  },
  {
    path: "/signup",
    element: <SignUp />,
    protected: false,
  },
];

export default AUTH_ROUTES;
