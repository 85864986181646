import { create } from "zustand";

import { devtools, persist } from "zustand/middleware";

export const authStore = (set) => ({
  email: "",
  userLog: null,
  addEmail: (email) => {
    set(() => ({
      email: email,
    }));
  },
  addUser: (userData) => {
    set((state) => ({
      userLog: { ...state.userLog, ...userData },
    }));
  },
});

const useAuthStore = create(
  devtools(
    persist(authStore, {
      name: "users",
      getStorage: () => sessionStorage,
    })
  )
);

export default useAuthStore;
