import { useThemeContext } from "../modules/themes";

export const useThemeChange = () => {
  const { theme } = useThemeContext();

  const obj = {
    add: (className) => `${className} ${theme}`,
    theme,
  };

  return obj;
};
